.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --blurry-bg: rgba(255, 255, 255, 0.4);
  --menu-bg: rgba(255, 255, 255, 0.8);
  --shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: #293241;
  --clr-bg-alt: #3D5A80;
  --clr-fg: #E0FBFC;
  --clr-fg-alt: #98C1D9;
  --clr-primary: #EE6C4D;
  --blurry-bg: rgba(0, 0, 0, 0.4);
  --menu-bg: rgba(0, 0, 0, 0.8);
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}